import { queryParamsBuilder, updateCacheData } from 'utils/commonFunctions';

import { createApiInstance } from './createApiInstance';

const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (build) => ({
    getCustomer: build.query({
      query: () => '/get-customer',
      transformResponse: (res) => {
        return res;
      },
    }),
    setUserInfo: build.mutation({
      query: ({ customerId, ...body }) => {
        return {
          url: `/user/${customerId}`,
          method: 'PUT',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getCustomer',
              dispatch,
              extendedApi,
            },
            (draft) => {
              Object.keys(draft)?.length > 0 &&
                Object.keys(draft)?.forEach((key) => {
                  if (data[key]) {
                    draft[key] = data[key];
                  }
                });
            }
          );
        } catch (error) {
          console.log('error', error);
        }
      },
    }),
    setShippingDetails: build.mutation({
      query: ({ customerId, ...body }) => {
        return {
          url: `/shipping/${customerId}`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getOnboardingData',
              dispatch,
              extendedApi,
            },
            (draft) => {
              draft.shipping = {
                ...draft.shipping,
                ...data,
              };
            }
          );
        } catch (error) {
          console.log('error', error);
        }
      },
    }),
    setBillingDetails: build.mutation({
      query: ({ customerId, ...body }) => {
        return {
          url: `/billing/${customerId}`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getOnboardingData',
              dispatch,
              extendedApi,
            },
            (draft) => {
              draft.billing = {
                ...draft.billing,
                ...data,
              };
            }
          );
        } catch (error) {
          console.log('/billing  Error', error);
        }
      },
    }),
    getAllCustomer: build.query({
      query: () => '/get-all-customer',
      transformResponse: (res) => {
        return res;
      },
    }),
    getCustomerById: build.query({
      query: (id) => `/customers/${id}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getOnboardingData: build.query({
      query: () => '/get-onboarding-data',
      transformResponse: (res) => {
        return res;
      },
    }),
    getOnboardingDataById: build.query({
      query: (query) =>
        `/get-onboarding-data-by-id${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getDefaultOptionsData: build.query({
      query: () => '/get-default-options',
      transformResponse: (res) => {
        return res;
      },
    }),
    getDefaultOptionsByProject: build.query({
      query: (query) =>
        `/get-default-options-by-project${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    confirmAccountVerification: build.query({
      query: ({ token, email }) => `/confirm-verification/${token}/${email}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    verifyAccount: build.mutation({
      query(body) {
        return {
          url: '/verify-account',
          method: 'POST',
          body,
        };
      },
    }),
    setOnboarding: build.mutation({
      query(body) {
        return {
          url: '/set-onboarding',
          method: 'POST',
          body,
        };
      },
    }),
    setDefaultOptions: build.mutation({
      query(body) {
        return {
          url: '/set-default-options',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          updateCacheData(
            {
              state: getState(),
              method: 'getDefaultOptionsData',
              dispatch,
              extendedApi,
            },
            (draft) => {
              Object.assign(draft, {
                ...JSON.parse(JSON.stringify(draft)),
                ...data?.data,
              });
            }
          );
        } catch (error) {
          console.log('Error', error);
        }
      },
    }),
    addNewCustomer: build.mutation({
      query(body) {
        return {
          url: '/add-new-customer',
          method: 'POST',
          body,
        };
      },
    }),
    subscribeToNewsletter: build.mutation({
      query(body) {
        return {
          url: '/subscribe-newsletter',
          method: 'POST',
          body,
        };
      },
    }),
    addQuery: build.mutation({
      query(body) {
        return {
          url: '/add-query',
          method: 'POST',
          body,
        };
      },
    }),
    getSearchedUsers: build.query({
      query: (query) => `/customers${queryParamsBuilder(query)}`,
      transformResponse: (res) => {
        return res;
      },
    }),
    getFaq: build.query({
      query: () => ({
        url: '/faq',
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCustomerQuery,
  useSetUserInfoMutation,
  useSetShippingDetailsMutation,
  useSetBillingDetailsMutation,
  useGetAllCustomerQuery,
  useGetCustomerByIdQuery,
  useGetOnboardingDataQuery,
  useGetOnboardingDataByIdQuery,
  useGetDefaultOptionsDataQuery,
  useSetOnboardingMutation,
  useSetDefaultOptionsMutation,
  useConfirmAccountVerificationQuery,
  useVerifyAccountMutation,
  useAddNewCustomerMutation,
  useGetSearchedUsersQuery,
  useSubscribeToNewsletterMutation,
  useAddQueryMutation,
  useGetFaqQuery,
  useGetDefaultOptionsByProjectQuery,
  useLazyGetDefaultOptionsByProjectQuery,
} = extendedApi;
