import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import { changeErrFlagState } from 'store/slices/errorSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  border: '2px solid #000',
  outline: 'none',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  backgroundColor: 'white',
};

function Error429() {
  const [counter, setCounter] = useState(30);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setTimeout(() => {
      dispatch(changeErrFlagState(false));
    }, 30000);
  }, [dispatch]);
  React.useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  return (
    <Box>
      <Modal open={true} keepMounted>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            429. You hit the rate limit!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            We are sorry, but you have sent too many requests to us recently.
            Please try again in {counter} seconds.
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

export default Error429;
