import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedProject: {},
  selectedProjectForOptions: {},
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    updateSelectedProject: (state, action) => {
      state.selectedProject = { ...state.selectedProject, ...action.payload };
    },
    setSelectedProjectForOptions: (state, action) => {
      state.selectedProjectForOptions = action.payload;
    },
  },
});

export const {
  setSelectedProject,
  updateSelectedProject,
  setSelectedProjectForOptions,
} = projectSlice.actions;

export default projectSlice.reducer;
