import { combineReducers, configureStore } from '@reduxjs/toolkit';

import apiInstance from './apis/createApiInstance';
import errorReducer from './slices/errorSlice';
import projectReducer from './slices/projectSlice';
import userReducer from './slices/userSlice';

const rootReducer = combineReducers({
  [apiInstance.reducerPath]: apiInstance.reducer,
  user: userReducer,
  error: errorReducer,
  project: projectReducer,
});
const reducer = (state, action) => {
  if (action.type === 'logout') {
    // state = {};
  }
  return rootReducer(state, action);
};
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiInstance.middleware
    ),
});
