import { LIGHT } from 'theme-loader/theme/colors';
import { pxToRem, themeTypography } from 'theme-loader/theme/typography';

const theme = {
  palette: LIGHT,
  typography: themeTypography,
  status: {
    danger: '#e48d8d',
    success: '#97d69d',
    primary: LIGHT.primary.main,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: pxToRem(20),
          fontWeight: '400',
          textTransform: 'none',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(15),
          fontWeight: '400',
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      variants: [
        {
          props: { color: 'standard' },
          style: {
            'borderRadius': 0,
            '&.Mui-selected': {
              backgroundColor: '#f0f0f0',
              fontWeight: 600,
              color: '#707070',
            },
          },
        },
      ],
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          'borderRadius': 0,
          'border': 'solid 1px #BFBFC0',
          '& .MuiToggleButton-root': {
            margin: 4,
            borderColor: 'transparent',
          },
        },
      },
    },
  },
};
export default theme;
