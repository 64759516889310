import * as React from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import Error429 from 'components/error-429/Error429';
import useAuth, { AuthRedirect } from 'context/AuthContext';
import Material from 'pages/select-material/Material';
import SelectMaterial from 'pages/select-material/SelectMaterial';
import { useGetCustomerQuery } from 'store/apis/customer';
// import { getCookie } from 'utils/commonFunctions';

const CreateNewOrderRouting = React.lazy(() =>
  import('pages/create-new-order/CreateNewOrderRouting')
);

const ShopHome = React.lazy(() => import('pages/shop-home/ShopHome'));
const ContactUs = React.lazy(() => import('pages/contact-us/ContactUs'));
const ResponsiveAppBar = React.lazy(() => import('components/appbar/AppBar'));
const Footer = React.lazy(() => import('components/footer/Footer'));
const RequestSupport = React.lazy(() =>
  import('pages/request-support/RequestSupport')
);
const CheckoutDetails = React.lazy(() =>
  import('pages/checkout-details/CheckoutDetails')
);
const CustomizeCabinet = React.lazy(() =>
  import('pages/customize-cabinet/CustomizeCabinet')
);
const CategoryPage = React.lazy(() =>
  import('pages/category-page/CategoryPage')
);
const FAQ = React.lazy(() => import('pages/faq/FAQ'));
const MyAccount = React.lazy(() => import('pages/my-account/MyAccount'));
const Projects = React.lazy(() => import('pages/projects/Projects'));
const YourOrder = React.lazy(() => import('pages/your-order/YourOrder'));
const SalesCart = React.lazy(() => import('pages/sales-cart/SalesCart'));
const OnBoarding = React.lazy(() => import('pages/onboarding/OnBoarding'));
const Orders = React.lazy(() => import('pages/orders/Orders'));
const ResetPassword = React.lazy(() =>
  import('pages/reset-password/ResetPassword')
);
const ReviewYourOrder = React.lazy(() =>
  import('pages/review-your-order/ReviewYourOrder')
);
const ThankYou = React.lazy(() => import('pages/thank-you/ThankYou'));
const ShippingInformation = React.lazy(() =>
  import('pages/shipping-information/ShippingInformation')
);
const CheckEmail = React.lazy(() => import('pages/check-email/CheckEmail'));
const ConfirmVerificationEmail = React.lazy(() =>
  import('./pages/confirm-email/ConfirmVerificationEmail')
);
const CheckVerificationEmail = React.lazy(() =>
  import('./pages/check-email/CheckVerificationEmail')
);
const ForgotPassword = React.lazy(() =>
  import('pages/forgot-password/ForgotPassword')
);
const HomeLayout = React.lazy(() => import('layouts/HomeLayout'));
const AuthLayout = React.lazy(() => import('layouts/AuthLayout'));
const PublicPageLayout = React.lazy(() => import('layouts/PublicPageLayout'));
const SignIn = React.lazy(() => import('pages/sign-in/SignIn'));
const AddNewCustomer = React.lazy(() =>
  import('pages/add-new-customer/AddNewCustomer')
);

const SelectOptions = React.lazy(() =>
  import('pages/default-options/SelectOptions')
);
const SignUp = React.lazy(() => import('pages/sign-up/SignUp'));
const PaymentInformation = React.lazy(() =>
  import('pages/payment-information/PaymentInformation')
);

export default function AppRouting() {
  // const userToken = getCookie('token');
  const auth = useAuth();
  const { data: user } = useGetCustomerQuery(
    {},
    { skip: !auth?.authenticated }
  );

  const errFlag = useSelector((state) => state.error.errFlag);

  // const defaultNavigate = <Navigate to={userToken ? './' : './sign-in'} />;
  const defaultNavigate = <Navigate to={'./'} />;

  const getRouteWrapper = (
    component,
    authRoute = true,
    isPublicRoute = false
  ) => {
    return (
      <AuthRedirect
        authenticatedRoute={authRoute}
        isPublicRoute={isPublicRoute}
      >
        <React.Suspense
          fallback={
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          }
        >
          {component}
        </React.Suspense>
      </AuthRedirect>
    );
  };

  const routes = useRoutes([
    {
      path: '/',
      element: getRouteWrapper(<HomeLayout />, false, true),
      children: [
        {
          index: true,
          element: getRouteWrapper(<ShopHome />, false, true),
        },
      ],
    },
    {
      path: '/customize',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<CustomizeCabinet />),
        },
      ],
    },
    {
      path: '/your-order',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<YourOrder />),
        },
      ],
    },
    {
      path: '/sales-cart',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<SalesCart />),
        },
      ],
    },
    {
      path: '/checkout-details',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<CheckoutDetails />),
        },
      ],
    },
    {
      path: '/payment-information',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<PaymentInformation />),
        },
      ],
    },
    {
      path: '/shipping-information',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<ShippingInformation />),
        },
      ],
    },
    {
      path: '/review-your-order',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<ReviewYourOrder />),
        },
      ],
    },
    {
      path: '/thank-you',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<ThankYou />),
        },
      ],
    },
    {
      path: '/cabinet/:id',
      element: getRouteWrapper(<HomeLayout />, false, true),
      children: [
        {
          index: true,
          element: getRouteWrapper(<CategoryPage />, false, true),
        },
      ],
    },
    {
      path: '/onboarding',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<OnBoarding />),
        },
      ],
    },
    {
      path: 'my-account/:path',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<MyAccount />),
        },
      ],
    },
    {
      path: 'projects/:path',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<Projects />),
        },
      ],
    },
    {
      path: '/faq',
      element: getRouteWrapper(<HomeLayout />, false, true),
      children: [
        {
          index: true,
          element: getRouteWrapper(<FAQ />, false, true),
        },
      ],
    },
    {
      path: '/request-support',
      element: getRouteWrapper(<HomeLayout />, false, true),
      children: [
        {
          index: true,
          element: getRouteWrapper(<RequestSupport />, false, true),
        },
      ],
    },
    {
      path: '/contact-us',
      element: getRouteWrapper(<HomeLayout />, false, true),
      children: [
        {
          index: true,
          element: getRouteWrapper(<ContactUs />, false, true),
        },
      ],
    },
    {
      path: '/orders',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<Orders />),
        },
      ],
    },
    {
      path: '/create-new-order',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          path: '*',
          element: getRouteWrapper(
            <CreateNewOrderRouting isAdmin={user?.role === 'ADMIN'} />
          ),
        },
      ],
    },
    {
      path: '/select-default-option',
      element: getRouteWrapper(<AuthLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<SelectOptions />),
        },
      ],
    },
    {
      path: '/update-default-option',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<SelectOptions />),
        },
      ],
    },
    {
      path: '/add-customer',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<AddNewCustomer />),
        },
      ],
    },
    {
      path: '/select-material',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<SelectMaterial />),
        },
      ],
    },
    {
      path: '/select-material/:materialId',
      element: getRouteWrapper(<HomeLayout />),
      children: [
        {
          index: true,
          element: getRouteWrapper(<Material />),
        },
      ],
    },
    {
      path: '/',
      element: getRouteWrapper(<PublicPageLayout />, false),
      children: [
        {
          path: '/sign-in',
          element: getRouteWrapper(<SignIn />, false),
        },
        {
          path: '/admin-sign-in',
          element: getRouteWrapper(
            <SignIn isAdmin={user?.role === 'ADMIN'} />,
            false
          ),
        },
        {
          path: '/sign-up',
          element: getRouteWrapper(<SignUp />, false),
        },
        {
          path: '/forgot-password',
          element: getRouteWrapper(<ForgotPassword />, false),
        },
        {
          path: '/check-email',
          element: getRouteWrapper(<CheckEmail />, false),
        },
        {
          path: '/reset-password/:token/:email',
          element: getRouteWrapper(<ResetPassword />, false),
        },
        {
          path: '/check-verification-email',
          element: getRouteWrapper(<CheckVerificationEmail />, false),
        },
        {
          path: '/confirm-verification/:token/:email',
          element: getRouteWrapper(<ConfirmVerificationEmail />, false),
        },
        {
          index: true,
          element: defaultNavigate,
        },
      ],
    },
    {
      path: '*',
      element: defaultNavigate,
    },
  ]);
  const location = useLocation();
  const mainContainerRef = React.useRef();
  React.useEffect(() => {
    mainContainerRef.current.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <Box
      ref={mainContainerRef}
      id="box-content"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowX: 'hidden',
      }}
    >
      <Box>
        {errFlag ? (
          <Stack height={'100%'}>
            <Error429 />
          </Stack>
        ) : null}
        <ResponsiveAppBar />
        <Box sx={{ marginTop: { xs: 22, sm: 0 } }}>{routes}</Box>
      </Box>
      <Footer />
    </Box>
  );
}
