import * as React from 'react';

import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

const TEXTURE_PRODUCTS = [
  {
    id: 'lummia',
    label: 'LUMMIA',
    count: 33,
    children: [
      { id: 'high-gloss', label: 'High Gloss', count: 13 },
      { id: 'perfect-matt', label: 'Perfect Matt', count: 20 },
    ],
  },
  {
    id: 'sommet-series',
    label: 'SOMMET SERIES',
    count: 27,
    children: [
      { id: 'feria', label: 'FERIA', count: 6 },
      { id: 'brava', label: 'BRAVA', count: 7 },
      { id: 'viva', label: 'VIVA', count: 14 },
    ],
  },
  {
    id: 'prelude-series',
    label: 'PRÉLUDE SERIES',
    count: 88,
    children: [
      { id: 'karisma', label: 'Karisma', count: 18 },
      { id: 'alto', label: 'Alto', count: 10 },
      { id: 'urbania', label: 'Urbania', count: 9 },
      { id: 'isola', label: 'Isola', count: 12 },
      { id: 'smoothwood', label: 'Smoothwood', count: 8 },
      { id: 'crystalite', label: 'Crystalite', count: 31 },
    ],
  },
];

export default function FilterTree() {
  const [selectedItems, setSelectedItems] = React.useState([]);

  const areAllChildrenSelected = (children, selectedItems) => {
    return children.every((child) => selectedItems.includes(child.id));
  };
  const handleParentSelection = (itemId, children = []) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prev) => [
        ...prev,
        itemId,
        ...children.map((child) => child.id),
      ]);
    } else {
      setSelectedItems((prev) =>
        prev.filter(
          (item) =>
            item !== itemId && !children.some((child) => child.id === item)
        )
      );
    }
  };

  const handleChildSelection = (childId, parentId, children) => {
    setSelectedItems((prevSelected) => {
      let newSelectedItems;
      if (!prevSelected.includes(childId)) {
        newSelectedItems = [...prevSelected, childId];

        if (areAllChildrenSelected(children, newSelectedItems)) {
          newSelectedItems = [...newSelectedItems, parentId];
        }
      } else {
        newSelectedItems = prevSelected.filter((item) => item !== childId);

        newSelectedItems = newSelectedItems.filter((item) => item !== parentId);
      }
      return newSelectedItems;
    });
  };
  return (
    <Box sx={{ minHeight: 352, minWidth: 290 }}>
      <SimpleTreeView
        checkboxSelection
        slots={{
          expandIcon: () => <></>,
          collapseIcon: () => <></>,
          // endIcon: () => <></>,
        }}
        multiSelect
        expandedItems={TEXTURE_PRODUCTS?.map((item) => item?.id)}
        selectedItems={selectedItems?.map((item) => item)}
      >
        {TEXTURE_PRODUCTS?.map((item) => (
          <TreeItem
            key={item?.id}
            itemId={item?.id}
            label={item?.label}
            onClick={() => handleParentSelection(item.id, item.children)}
          >
            {item?.children?.map((child) => (
              <TreeItem
                key={child?.id}
                itemId={child?.id}
                label={child?.label}
                onClick={() =>
                  handleChildSelection(child.id, item.id, item.children)
                }
              />
            ))}
          </TreeItem>
        ))}
      </SimpleTreeView>
    </Box>
  );
}
