import 'App.css';

import { ToastContainer } from 'react-toastify';

import AppRouting from 'AppRouting';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <AppRouting />
      <ToastContainer />
    </>
  );
}

export default App;
