import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errFlag: false,
};

export const errorSlice = createSlice({
  name: 'ErrorPage',
  initialState: initialState,
  reducers: {
    changeErrFlagState: (state, action) => {
      state.errFlag = action.payload;
    },
  },
});

export const { changeErrFlagState } = errorSlice.actions;
const errorPage = errorSlice.reducer;
export default errorPage;
