import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

import { extendedApi } from 'store/apis/order';
import { globalVariable } from 'utils/globalVariables';

import { createApiInstance } from '../store/apis/createApiInstance';
import {
  deleteAllCookies,
  getCookie,
  setCookie,
} from '../utils/commonFunctions';

/**
 * Accepts stored token from local storage
 */
// const token = localStorage.getItem('token');
const token = getCookie('token') ? JSON.parse(getCookie('token')) : false;

/**
 * Creates context for authentication
 */
const AuthContext = React.createContext({
  authenticated: null,
  setAuth: function () {},
  removeAuth: function () {},
});
/**
 * Provides authentication for authenticated pages
 */
export function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authData, setAuthData] = useState({
    token,
  });

  /**
   * Removes authentication when Logging out
   */
  const removeAuth = () => {
    dispatch(createApiInstance.util.resetApiState());
    // re-fetch cabinet data on logout
    dispatch(extendedApi.endpoints.getAllMaterials.initiate());
    dispatch({ type: 'logout' });
    deleteAllCookies();
    // localStorage.removeItem('token');
    setAuthData({ token: null });
    navigate('/sign-in');
  };

  /**
   * Stores authenticated token when Signing in
   */
  const addAuth = ({ token }) => {
    setCookie('token', token);
    // localStorage.setItem('token', token);
    setAuthData({ token });
  };

  // const addAuth = ({ token, superToken }) => {
  //   setCookie('token', JSON.stringify(token));
  //   setCookie('superToken', JSON.stringify(superToken));
  //   setAuth({ token, superToken });
  // };

  const value = {
    authenticated: authData?.token,
    setAuth: addAuth,
    removeAuth,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.element,
};

/**
 * Returns auth data from context
 */
export default function useAuth() {
  return React.useContext(AuthContext);
}

/**
 * If user is not authenticated the redirects to Sign In page otherwise respective route page
 */
export const AuthRedirect = ({
  children,
  authenticatedRoute = true,
  isPublicRoute = false,
}) => {
  let auth = useAuth();
  let location = useLocation();
  const selectedCabinet = { ...globalVariable.selectedCabinet };

  if (!isPublicRoute) {
    if (!auth?.authenticated && authenticatedRoute) {
      return <Navigate to="/sign-in" state={{ from: location }} />;
    } else if (auth?.authenticated && !authenticatedRoute) {
      if (
        auth.authenticated &&
        (Object.keys(globalVariable.selectedCabinet).length ||
          Object.keys(selectedCabinet)?.length)
      ) {
        return (
          <Navigate
            to="/customize"
            state={{
              from: location,
              selectedCabinet: selectedCabinet,
            }}
          />
        );
      } else {
        return <Navigate to="/" state={{ from: location }} />;
      }
    } else {
      return children;
    }
  }
  return children;
};

AuthRedirect.propTypes = {
  authenticatedRoute: PropTypes.bool,
  children: PropTypes.element,
  isPublicRoute: PropTypes.bool,
};
