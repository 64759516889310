export const primaryColor = {
  100: '#fdf0ed',
  200: '#f9d2c8',
  300: '#f4b4a4',
  400: '#f2a591',
  500: '#f0967f',
  600: '#ee876d',
  700: '#ea6948',
  800: '#e75136',
  900: '#E54A25', // Changed Color to Orange
  1000: '#db401a',
};

export const primaryBlack = '#1A1919';

/**
 * Secondary color shades
 */
export const secondaryColor = {
  100: '#FFF3ED',
  200: '#FFDCCA',
  300: '#FFD1B8',
  400: '#FEBA94',
  500: '#FEAE83',
  600: '#FE975F',
  700: '#FE8B4D',
  800: '#FE6918',
  900: '#FE5E05',
};

export const LIGHT = {
  primary: {
    main: primaryColor[900],
    light: primaryColor[400],
    dark: primaryColor[1000],
  },
  secondary: {
    main: secondaryColor[900],
    light: secondaryColor[400],
    dark: secondaryColor[900],
  },
  grey: {
    50: '#F8F9FA',
    100: '#d1d5db',
    200: '#9CA3AF69',
    300: '#c5c6c7',
    400: '#CFD4D9',
  },
  text: {
    primary: '#212529',
  },
  sub: {
    bsIndigo: '#6610F2',
    bsPurple: '#6F42C1',
    bsPink: '#D63384',
    bsRed: '#DC3545',
    bsOrange: '#E54A25',
    bsYellow: '#FFC107',
    bsGreen: '#198754',
    bsTeal: '#20C997',
    bsCyan: '#0DCAF0',
    bsGray: '#6C757D',
    bsDarkGray: '#343A40',
    bsDark: '#212529',
    bsLight: '#F8F9FA',
    bsWhite: '#FFFFFF',
    prefilled: '#EFEFEF',
    bsLightGreen: '#20C9971A',
  },
  type: 'Light',
};
