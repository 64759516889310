export const stringToColor = (string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const stringAvatar = (name) => {
  return {
    style: {
      backgroundColor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};
export const jwtDecode = (t) => {
  if (t.includes('.')) {
    let token = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return token;
  }
  return t;
};
export const setCookie = (cname, cvalue) => {
  if (cvalue) {
    const jwtData = jwtDecode(cvalue);
    const d = jwtData.payload.exp
      ? new Date(jwtData?.payload?.exp * 1000).toUTCString()
      : new Date(new Date().getTime() + 60 * 60 * 1000).toUTCString();
    const expires = 'expires=' + d;
    document.cookie =
      cname + '=' + btoa(JSON.stringify(cvalue)) + ';' + expires + ';path=/';
  }
};

export const getCookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0 && name.length !== c.length) {
      return atob(c.substring(name.length, c.length));
    }
  }
  return '';
};

export const deleteCookie = (cname) => {
  document.cookie = cname + '=; Path=/;max-age=0';
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    deleteCookie(name);
  }
};

export const queryParamsBuilder = (query) => {
  if (typeof query !== 'object') {
    return '';
  }
  const keys = Object.keys(query)?.filter(
    (b) => query[b] !== null && query[b] !== ''
  );
  if (keys.length) {
    const queryParams = keys?.reduce((params, key) => {
      const value = query[key];
      if (Array.isArray(value)) {
        value?.forEach((val) => params.append(key, val));
      } else {
        params.set(key, value);
      }
      return params;
    }, new URLSearchParams());
    return `?${queryParams.toString()}`;
  }
  return '';
};

export function updateCacheData({ state, method, dispatch, extendedApi }, cb) {
  const { api } = state;
  let cacheArgToUpdate = [undefined];
  if (api) {
    cacheArgToUpdate = Object.keys(api.queries)
      ?.filter(
        (d) =>
          d.indexOf(`${method}(`) === 0 &&
          Object.keys(api.queries[d]).length > 0
      )
      .map((d) => api.queries[d].originalArgs);
  }
  return cacheArgToUpdate?.forEach((originalArgs) =>
    dispatch(extendedApi.util.updateQueryData(method, originalArgs, cb))
  );
}

export function getDepartmentName(departmentId, data) {
  const departmentName = data?.find((item) => item._id === departmentId);
  return departmentName?.department;
}

export const sortObject = (unordered) => {
  return Object.keys(unordered)
    .sort()
    ?.reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
};

export function deepComparison(first, second) {
  /* Checking if the types and values of the two arguments are the same. */
  if (first === second) return true;

  /* Checking if any arguments are null */
  if (first === null || second === null) return false;

  /* Checking if any argument is none object */
  if (typeof first !== 'object' || typeof second !== 'object') return false;

  /* Using Object.getOwnPropertyNames() method to return the list of the objects’ properties */
  let first_keys = Object.getOwnPropertyNames(first);

  let second_keys = Object.getOwnPropertyNames(second);

  /* Checking if the objects' length are same*/
  if (first_keys.length !== second_keys.length) return false;

  /* Iterating through all the properties of the first object with the for of method*/
  for (let key of first_keys) {
    /* Making sure that every property in the first object also exists in second object. */
    if (!Object.hasOwn(second, key)) return false;

    /* Using the deepComparison function recursively (calling itself) and passing the values of each property into it to check if they are equal. */
    if (deepComparison(first[key], second[key]) === false) return false;
  }

  /* if no case matches, returning true */
  return true;
}

export const b64toBlob = (
  b64Data = '',
  contentType = 'application/pdf',
  sliceSize = 512
) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const downloadPDF = ({ blobUrl, fileName }) => {
  const downloadLink = document.createElement('a');
  downloadLink.target = '_blank';
  downloadLink.href = blobUrl;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

export function areArraysIdentical(arr1, arr2) {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort the arrays
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  // Compare sorted arrays
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export const formatPhoneNumber = (phoneNumber) => {
  return phoneNumber?.replace(/(\+1)\s?(\d+)\s?(\d+)\s?(\d+)/, '$1 $2$3$4');
};

export const getLastWorkedOnProject = (projects) => {
  const lastWorkedOnProject =
    projects?.length &&
    JSON.parse(JSON.stringify(projects))?.sort((a, b) => {
      if (a?.lastWorkedOn && b?.lastWorkedOn) {
        return new Date(b.lastWorkedOn) - new Date(a.lastWorkedOn);
      } else if (a?.lastWorkedOn) {
        return -1;
      } else if (b?.lastWorkedOn) {
        return 1;
      } else {
        return 0;
      }
    });
  const lastWorkedOn = lastWorkedOnProject?.at(0);
  return lastWorkedOn;
};
